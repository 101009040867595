import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
//not sure how to use theme props w/ typescript... so being forces to import
//this way. Typescript giving and error (line 28)
import { colors } from '../../GlobalStyles';
import arrow from '../../../images/icons/black-arrow-down.svg';
import plus from '../../../images/plus-sign.svg';
import { navlinks } from './NavLinks';
import { YellowButton } from '../StyledComponents';
import yellowArrow from '../../../images/icons/green-arrow-right.svg';
import { HomeHeroPanel } from '../../HomeHeroPanel';
import greenArrow from '../../../images/icons/green-arrow-right.svg';

interface StyledProps {
    activeSlide: boolean;
}

const Button = styled(YellowButton)``;

const StyledNavSlide = styled.div<StyledProps>`
    li {
        color: ${colors.white};
    }
    .hidden {
        display: none;
    }
    .buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
        button {
        }
    }
    .plus {
    }
    ul {
        padding: 0;
    }
    button {
        border: none;
        background: none;
    }
    display: flex;
    justify-content: center;
    position: absolute;
    background: rgba(0, 103, 71, 1);
    color: ${colors.white};
    height: 100vh;
    width: 100vw;
    z-index: 1;
    top: 0vh;
    transform: ${({ activeSlide }) => (!activeSlide ? 'translateX(100%)' : 'translateX(00%)')};
    transition: 0.1s ease-in all;
    .master-list {
        width: 100%;

        position: absolute;
        overflow: hidden;
        transition: all 0.2s ease-in;
        list-style-type: none;
        margin-top: 50px;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        img {
            -webkit-filter: grayscale(1) invert(1);
            filter: grayscale(1) invert(1);
            max-height: 10px;
        }
        .first-items {
            font-weight: 700;
            color: ${colors.white};
            margin: 10px 0px;
            font-size: 20px;
            img {
                margin-left: 0.5em;
            }
        }
        .second-items {
            font-size: 18px;
            margin: 7.5px 0px;
        }
        .subdropdown {
            overflow-y: scroll;
            max-height: 27vh;
        }

        li {
            font-weight: 600;
            display: flex;
            width: 280px;
            justify-content: center;
            align-items: center;
        }
        .special-items {
            margin: 0em 0em;
            width: 300px;
        }
    }
    .dropdown {
        li {
            position: relative;
            margin: 10px 0px;
            min-width: 280px;
            img {
                position: absolute;
                left: 14em;
            }
        }
        padding: 0;
    }
`;

const Panel = styled.section<Props>`
    position: absolute;
    bottom: 1.1875rem;
    background: rgba(51, 51, 51, 0.2);
    backdrop-filter: blur(32px);
    border-radius: 16px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    padding: 0em 1em;
    margin-bottom: 1em;
    button:last-of-type {
        cursor: pointer;
        color: ${colors.yellow};
        display: flex;
        border: none;

        min-width: 240px;
        padding: 0.5em;
        justify-content: center;
        background: ${colors.yellowGradient};
        margin: 0.75em 0em;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.375rem;
        letter-spacing: 3px;
        img {
            margin-left: 0.5em;
            stroke: ${colors.yellow};
        }
        div {
            display: flex;
        }
    }
`;

interface Props {
    setActiveSlide(arg: boolean): boolean;
    activeSlide: boolean;
    onChoiceClick(arg: string): string;
}

export const NavSlide: React.FC<Props> = ({ setActiveSlide, activeSlide }) => {
    const [dropMenu, setDropMenu] = useState(null);
    const [choice, setChoice] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [secondClicked, setSecondClicked] = useState(false);
    const [secondChoice, setSecondChoice] = useState(null);
    const [className, setClassName] = useState('');
    const onChoiceClick = (dropdown, name) => {
        if (name === choice) {
            setDropMenu(null);
        }
        if (clicked) {
            setDropMenu(dropdown);
        }

        if (dropMenu) {
            setSecondChoice(null);
        }
        setChoice(name);
    };

    const onSecondChoiceClick = (link, name) => {
        if (secondClicked) {
            setDropMenu(link);
        }
        if (!secondClicked) {
            setClassName('hidden');
        }
        if (secondClicked) {
            setSecondChoice(null);
        } else {
            setDropMenu(link);
            setSecondChoice(name);
        }
    };

    const finalClick = () => {
        setActiveSlide(false);
        setDropMenu(null);
        setSecondChoice(null);
    };
    return (
        <StyledNavSlide activeSlide={activeSlide}>
            <ul className="master-list">
                {navlinks.map(link => (
                    <div key={link.name}>
                        <button
                            onClick={() => {
                                setClicked(prev => !prev);
                                onChoiceClick(link.dropdown, link.name);
                            }}
                        >
                            <li className={`${className} first-items`}>
                                {link.name}
                                <img src={plus} className="plus" alt="green plus sign" />
                            </li>
                        </button>
                        <ul className="dropdown">
                            {dropMenu &&
                                choice === link.name &&
                                link.dropdown.map((dropdown, index) => (
                                    <div key={dropdown.name}>
                                        <Link to={dropdown.url}>
                                            <button
                                                onClick={() => {
                                                    setSecondClicked(prev => !prev);

                                                    onSecondChoiceClick(
                                                        link.dropdown[index].subdropdown,
                                                        dropdown.name
                                                    );

                                                    if (link.name !== 'SERVICES') {
                                                        setActiveSlide(false);
                                                        finalClick();
                                                    }
                                                }}
                                            >
                                                <li className="second-items">
                                                    {choice === 'SERVICES' ? (
                                                        <span className="special-items">
                                                            {dropdown.name}
                                                        </span>
                                                    ) : (
                                                        `${dropdown.name}`
                                                    )}
                                                    {choice === 'SERVICES' && (
                                                        <img
                                                            className="special-arrow"
                                                            src={arrow}
                                                            alt="black arrow pointing down"
                                                        />
                                                    )}
                                                </li>
                                            </button>
                                        </Link>
                                        {secondChoice === dropdown.name && (
                                            <ul className="subdropdown">
                                                {dropMenu.map(item => (
                                                    <Link key={item.name} to={item.url}>
                                                        <li
                                                            onClick={() => {
                                                                finalClick();
                                                            }}
                                                        >
                                                            {item.name}
                                                        </li>
                                                    </Link>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ))}
                        </ul>
                    </div>
                ))}
            </ul>
            <Panel>
                <div>
                    <button onClick={() => finalClick()} className="second">
                        <Link style={{ color: `${colors.green}` }} to="/patient-resources/forms">
                            FORMS
                        </Link>
                        <img src={yellowArrow} alt="yellow arrow pointing right" />
                    </button>
                    <Link to="/appointments/">
                        <button style={{ color: `${colors.green}` }} className="second">
                            APPOINTMENTS
                            <img src={greenArrow} alt="black arrow pointing right" />
                        </button>
                    </Link>
                </div>
            </Panel>
        </StyledNavSlide>
    );
};
